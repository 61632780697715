import React from "react"
import ReactDOMServer from "react-dom/server"

export default function Email() {
  let elem = (
    <html>
      <head></head>
      <body>
        <div style={{ fontFamily: "Verdana" }}>
          <div id="email-body">
            <a href="#" style={{ cursor: "default" }}>
              <img style={{ width: "600px" }} src="cid:header.png" />
            </a>
            <br />

            <div style={{ width: "600px" }}>
              <div>
                <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
                  <br />
                  <span
                    id="email-header"
                    style={{
                      color: "#D05476",
                      fontSize: "26pt",
                      fontWeight: "regular",
                    }}
                  >
                    hello there
                  </span>
                  <br />
                  <br />
                  <div
                    style={{
                      color: "#3c3d3b",
                    }}
                  >
                    it’s us here, live from MatchmakerHQ - bringing you the news you've been waiting for... It's time you meet the first match of your search for your one and only valentine
                  </div>
                  <br />
                  <div>
                    <br />
                    <span
                      style={{
                        color: "#717375",
                        fontSize: "22pt",
                        fontWeight: "bold",
                      }}
                    >
                      YOUR FIRST MATCH IS...
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div
              id="matchbox"
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                backgroundColor: "#D05476",
                width: "600px",
                color: "#f1f1f1",
                fontSize: "14pt",
                fontFamily: "'Helvetica', sans-serif",
              }}
            >
              <div
                id="matchbox-text"
                style={{ textAlign: "left", width: "600px" }}
              >
                <center>
                  <a
                    style={{ textDecoration: "underline", color: "#f1f1f1" }}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdPkmAly_e9tQpLfJ530jGnAdhSP2yq0L0n110r7eNOSt0ZCA/viewform?usp=sf_link"
                  >
                    FILL OUT THE SECOND-CHANCE MATCH SURVEY HERE
                  </a>
                </center>
              </div>
            </div>

            <div style={{ width: "600px" }}>
              <div
                style={{
                  color: "#3c3d3b",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <br />
                <br />
                Are you as excited as we are?? The race to find your valentine begins today. Reach out to your match to schedule a date as soon as possible!
                <br />
                <br />
                And because we really do care about your romantic life...here's a coupon to subzero for you and your date :)
                <br />
                <br />
                <span style={{ fontWeight: "bold", color: "#D05476" }}>
                  The MatchmakerPROVO Team:
                </span>
                <br />
                Marketing Girl, the Brain, Coder Guy, <br />
                the Hustler, the Typewriter and as always, Cupid.
              </div>
            </div>
            <br />
            <div
              style={{
                backgroundColor: "#D05476",
                height: "40px",
                width: "600px",
              }}
            ></div>
            
          </div>
        </div>
      </body>
    </html>
  )
  console.log(ReactDOMServer.renderToStaticMarkup(elem))
  return elem
}
